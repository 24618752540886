.button {
    background: linear-gradient(#90A4AE, white, #90A4AE);
    border: 1px solid rgb(0, 0, 0);
    border-radius: 15px;
    font-family: Trebuchet MS;
    margin: 5px;
}

.button:hover {
    color: white;
    background: linear-gradient(white, #90A4AE, white);
    border: 1px solid rgb(255, 255, 255);
    transform: scale(1.1);
    transition: 0.5s;
}

.logo:hover {
    transform: scale(1.1);
    transition: 0.5s;
}

.sportage:hover {
    opacity: .8;
    box-shadow: 0px 0px 15px 5px rgb(102, 202, 156);
    transition: 2s;
}

.bodega{
    background-color:transparent;
    width: 130px;
    height: 130px;
    border-radius:50%; 
    box-shadow:0px 0px 5px 2px rgb(255, 255, 255); 
    display: flex;
    justify-content: center;
    align-items: center;
}